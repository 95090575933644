import { ServicesViewOption } from "components/common/ServiceViewDropdown";

export const ROOT_PATH = "/";
export const DASHBOARD_PAGE_PATH = "/dashboard";
export const ACCESS_DENIED_PAGE_PATH = "/access-denied";

export const REFERENCE_BOOKS_PATH = "reference-books";
export const DEFECTS_PAGE_PATH = "defects";
export const MAINTAINABILITY_PAGE_PATH = "maintainability";
export const SERIAL_NUMS_TRACKING_PAGE_PATH = "serial-numbers-tracking";
export const NORMHOURS_PAGE_PATH = "groups-reference-books/norm-hours";
export const DEVICES_PATH = "groups-reference-books/items";
export const INVOICE_NOTES_PATH = "invoice-notes";
export const WORK_EXECUTED_PATH = "work-executed";
export const DEFECTS_PAGE_PATH_FULL = `/${REFERENCE_BOOKS_PATH}/${DEFECTS_PAGE_PATH}`;
export const MAINTAINABILITY_PAGE_PATH_FULL = `/${REFERENCE_BOOKS_PATH}/${MAINTAINABILITY_PAGE_PATH}`;
export const SERIAL_NUMS_TRACKING_PAGE_PATH_FULL = `/${REFERENCE_BOOKS_PATH}/${SERIAL_NUMS_TRACKING_PAGE_PATH}`;
export const NORMHOURS_PAGE_PATH_FULL = `/${REFERENCE_BOOKS_PATH}/${NORMHOURS_PAGE_PATH}`;
export const DEVICES_PATH_FULL = `/${REFERENCE_BOOKS_PATH}/${DEVICES_PATH}`;
export const INVOICE_NOTES_PATH_FULL = `/${REFERENCE_BOOKS_PATH}/${INVOICE_NOTES_PATH}`;
export const WORK_EXECUTED_PATH_FULL = `/${REFERENCE_BOOKS_PATH}/${WORK_EXECUTED_PATH}`;

export const OPERATIONS_PATH = "operations";
export const REQUESTS_RMA_PAGE_PATH = "requests-rma";
export const REQUESTS_RMA_SUBMITTED_PAGE_PATH = "requests-rma/submitted";
export const PERMISSIONS_RMA_PAGE_PATH = "permissions-rma";
export const INVOICES_PATH = "invoices";
export const REPAIR_PLAN_PATH = "repair-plan";
export const CUSTOMERS_INFO_PATH = "customers-info";
export const WORKSHOP_PATH = "workshop";
export const REPLACEMENT_DEVICES_PATH = "replacement-devices";
export const PROLONGED_LICENSES_PATH = "prolonged-licenses";
export const SERVICE_CENTERS_DATA_UPLOAD_PATH = "centers-data-upload";
export const SERVICE_PATH = "service";
export const SERVICE_PATH_APPROVAL_REQUIRED = `service${ServicesViewOption.RequiresAgreement}`;
export const SERVICE_PATH_EXPIRED = `service${ServicesViewOption.Expired}`;
export const SERVICE_PATH_DEVICES_APPROVED = `service${ServicesViewOption.DevicesApprovedToWorkshop}`;
export const SERVICE_PATH_DEFECTS_NOT_ENTERED = `service${ServicesViewOption.DefectsNotEntered}`;
export const SERVICE_PATH_NOT_A_WARRANTY = `service${ServicesViewOption.NotAWarranty}`;
export const SERVICE_PATH_COST_CONFIRMED = `service${ServicesViewOption.CostConfirmed}`;
export const SERVICE_PATH_REPAIR_REQUIRED = `service${ServicesViewOption.RepairRequired}`;
export const SERVICE_PATH_REQUEST_CONFIRMATION = `service${ServicesViewOption.RequestConfirmation}`;
export const SERVICE_PATH_REPLACEMENT_TO_NEW = `service${ServicesViewOption.ReplacementToNew}`;
export const NOTIFICATIONS_PATH = "/notifications";
export const FAQ_PATH = "/faq";
export const EQUIPMENT_PATH = "equipment";
export const OPERATIONS_PATH_FULL = `/${OPERATIONS_PATH}/${OPERATIONS_PATH}`;
export const REQUESTS_RMA_PAGE_PATH_FULL = `/${OPERATIONS_PATH}/${REQUESTS_RMA_PAGE_PATH}`;
export const REQUESTS_RMA_SUBMITTED_PAGE_PATH_FULL = `/${OPERATIONS_PATH}/${REQUESTS_RMA_SUBMITTED_PAGE_PATH}`;
export const PERMISSIONS_RMA_PAGE_PATH_FULL = `/${OPERATIONS_PATH}/${PERMISSIONS_RMA_PAGE_PATH}`;
export const INVOICES_PATH_FULL = `/${OPERATIONS_PATH}/${INVOICES_PATH}`;
export const REPAIR_PLAN_PATH_FULL = `/${OPERATIONS_PATH}/${REPAIR_PLAN_PATH}`;
export const CUSTOMERS_INFO_PATH_FULL = `/${OPERATIONS_PATH}/${CUSTOMERS_INFO_PATH}`;
export const WORKSHOP_PATH_FULL = `/${OPERATIONS_PATH}/${WORKSHOP_PATH}`;
export const REPLACEMENT_DEVICES_PATH_FULL = `/${OPERATIONS_PATH}/${REPLACEMENT_DEVICES_PATH}`;
export const PROLONGED_DEVICES_PATH_FULL = `/${OPERATIONS_PATH}/${PROLONGED_LICENSES_PATH}`;
export const SERVICE_CENTERS_DATA_UPLOAD_PATH_FULL = `/${OPERATIONS_PATH}/${SERVICE_CENTERS_DATA_UPLOAD_PATH}`;
export const SERVICE_PATH_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH}`;
export const SERVICE_PATH_APPROVAL_REQUIRED_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH_APPROVAL_REQUIRED}`;
export const SERVICE_PATH_EXPIRED_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH_EXPIRED}`;
export const SERVICE_PATH_DEVICES_APPROVED_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH_DEVICES_APPROVED}`;
export const NOTIFICATIONS_PATH_FULL = `/${OPERATIONS_PATH}/${NOTIFICATIONS_PATH}`;
export const SERVICE_PATH_DEFECTS_NOT_ENTERED_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH_DEFECTS_NOT_ENTERED}`;
export const SERVICE_PATH_NOT_A_WARRANTY_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH_NOT_A_WARRANTY}`;
export const SERVICE_PATH_COST_CONFIRMED_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH_COST_CONFIRMED}`;
export const SERVICE_PATH_REPAIR_REQUIRED_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH_REPAIR_REQUIRED}`;
export const SERVICE_PATH_REQUEST_CONFIRMATION_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH_REQUEST_CONFIRMATION}`;
export const SERVICE_PATH_REPLACEMENT_TO_NEW_FULL = `/${OPERATIONS_PATH}/${SERVICE_PATH_REPLACEMENT_TO_NEW}`;
export const EQUIPMENT_PATH_FULL = `/${OPERATIONS_PATH}/${EQUIPMENT_PATH}`;

export const REPORTS_PATH = "reports";
export const REPLACED_PARTS_REPORT_PATH = "replaced-parts-report";
export const COMPLIANCE_WITH_DECLARED_DEFECTS_PATH = "compliance-with-declared-defect";
export const LICENSES_USED_PATH = "licenses-used";
export const DEVICES_NOT_SHIPPED_PATH = "devices-not-shipped";
export const LIST_OF_COMPLAINTS_PATH = "list-of-complaints";
export const SO_REPAIR_DATA_PATH = "so-repair-data";
export const DEFECTS_TRANSFERRING_BY_WAREHOUSES_PATH = "transferring-by-warehouses";
export const REPAIR_TIME_REPORT_PATH = "repair-time-report";
export const QC_TIME_PATH = "qc-time";
export const FULFILLMENT_OF_REPAIR_PLANS_PATH = "fulfillment-of-rep-plans";
export const UNPLANNED_DEVICES_PATH = "unplanned-devices";
export const DEFECTS_TRANSFERRING_BY_CUSTOMERS_PATH = "transferring-by-customers";
export const DISTRIBUTOR_RATING_PATH = "distributor-rating";
export const COST_OF_TRANSPORTATION_PATH = "cost-of-transportation";
export const INCOME_FORECAST_PATH = "income-forecast";
export const DEVICES_SHIPPED_TO_REPAIR_PATH = "devices-shipped-to-repair";
export const RETURNS_REASONS_PATH = "returns-reasons";
export const REPAIR_PRICE_PROSTAFF_PATH = "repair-price-prostaff";
export const GENERAL_REPORT_AVERAGE_REPAIR_TIME_PATH = "general-report-average-repair-time";
export const REPAIRED_RETURN_PAGE_PATH = "repaired-returns";
export const DEVICE_ARRIVAL_TIME_REPORT_PATH = "device-arrival-time-report";
export const LIST_OF_COMPLAINTS_EXTERNAL_PATH = "list-of-external-complaints";
export const CATEGORY_A_PATH = "category-a";
export const PAID_REPAIR_PATH = "paid-repair";
export const RETURN_LEVEL_PATH = "return-level";
export const SERVICE_COSTS_PATH = "service-cost";
export const ADDED_PARTS_PATH = "added-parts";
export const UPGRADE_DEVICES_PATH = "upgrade-devices";
export const DISTRIBUTOR_DEVICES_PATH = "distributor-devices";
export const REPAIR_TIME_ANALYSIS_PATH = "repair-time-analysis";
export const AVERAGE_REPAIR_TIME_PATH = "average-repair-time-weekly";
export const EXCEEDING_REPAIRS_PATH = "exceeding-repairs";
export const REPAIRED_RETURNS_PATH = "repaired-returns-daily";
export const SPARE_PARTS_TOTAL_SPENDING_UPGRADABLE_PATH = "spare-parts-spending-upgradable";
export const REPLACED_PARTS_REPORT_PATH_FULL = `/${REPORTS_PATH}/${REPLACED_PARTS_REPORT_PATH}`;
export const COMPLIANCE_WITH_DECLARED_DEFECTS_PATH_FULL = `/${REPORTS_PATH}/${COMPLIANCE_WITH_DECLARED_DEFECTS_PATH}`;
export const LICENSES_USED_PATH_FULL = `/${REPORTS_PATH}/${LICENSES_USED_PATH}`;
export const DEVICES_NOT_SHIPPED_PATH_FULL = `/${REPORTS_PATH}/${DEVICES_NOT_SHIPPED_PATH}`;
export const LIST_OF_COMPLAINTS_PATH_FULL = `/${REPORTS_PATH}/${LIST_OF_COMPLAINTS_PATH}`;
export const SO_REPAIR_DATA_PATH_FULL = `/${REPORTS_PATH}/${SO_REPAIR_DATA_PATH}`;
export const DEFECTS_TRANSFERRING_BY_WAREHOUSES_PATH_FULL = `/${REPORTS_PATH}/${DEFECTS_TRANSFERRING_BY_WAREHOUSES_PATH}`;
export const REPAIR_TIME_REPORT_PATH_FULL = `/${REPORTS_PATH}/${REPAIR_TIME_REPORT_PATH}`;
export const QC_TIME_PATH_FULL = `/${REPORTS_PATH}/${QC_TIME_PATH}`;
export const FULFILLMENT_OF_REPAIR_PLANS_PATH_FULL = `/${REPORTS_PATH}/${FULFILLMENT_OF_REPAIR_PLANS_PATH}`;
export const UNPLANNED_DEVICES_PATH_FULL = `/${REPORTS_PATH}/${UNPLANNED_DEVICES_PATH}`;
export const DEFECTS_TRANSFERRING_BY_CUSTOMERS_PATH_FULL = `/${REPORTS_PATH}/${DEFECTS_TRANSFERRING_BY_CUSTOMERS_PATH}`;
export const DISTRIBUTOR_RATING_PATH_FULL = `/${REPORTS_PATH}/${DISTRIBUTOR_RATING_PATH}`;
export const COST_OF_TRANSPORTATION_PATH_FULL = `/${REPORTS_PATH}/${COST_OF_TRANSPORTATION_PATH}`;
export const INCOME_FORECAST_PATH_FULL = `/${REPORTS_PATH}/${INCOME_FORECAST_PATH}`;
export const DEVICES_SHIPPED_TO_REPAIR_PATH_FULL = `/${REPORTS_PATH}/${DEVICES_SHIPPED_TO_REPAIR_PATH}`;
export const RETURNS_REASONS_PATH_FULL = `/${REPORTS_PATH}/${RETURNS_REASONS_PATH}`;
export const REPAIR_PRICE_PROSTAFF_PATH_FULL = `/${REPORTS_PATH}/${REPAIR_PRICE_PROSTAFF_PATH}`;
export const GENERAL_REPORT_AVERAGE_REPAIR_TIME_PATH_FULL = `/${REPORTS_PATH}/${GENERAL_REPORT_AVERAGE_REPAIR_TIME_PATH}`;
export const DEVICE_ARRIVAL_TIME_REPORT_PATH_FULL = `/${REPORTS_PATH}/${DEVICE_ARRIVAL_TIME_REPORT_PATH}`;
export const LIST_OF_COMPLAINTS_EXTERNAL_PATH_FULL = `/${REPORTS_PATH}/${LIST_OF_COMPLAINTS_EXTERNAL_PATH}`;
export const CATEGORY_A_PATH_FULL = `/${REPORTS_PATH}/${CATEGORY_A_PATH}`;
export const PAID_REPAIR_PATH_FULL = `/${REPORTS_PATH}/${PAID_REPAIR_PATH}`;
export const RETURN_LEVEL_PATH_FULL = `/${REPORTS_PATH}/${RETURN_LEVEL_PATH}`;
export const REPAIRED_RETURN_PAGE_PATH_FULL = `/${REPORTS_PATH}/${REPAIRED_RETURN_PAGE_PATH}`;
export const SERVICE_COSTS_PATH_FULL = `/${REPORTS_PATH}/${SERVICE_COSTS_PATH}`;
export const ADDED_PARTS_PATH_FULL = `/${REPORTS_PATH}/${ADDED_PARTS_PATH}`;
export const UPGRADE_DEVICES_PATH_FULL = `/${REPORTS_PATH}/${UPGRADE_DEVICES_PATH}`;
export const DISTRIBUTOR_DEVICES_PATH_FULL = `/${REPORTS_PATH}/${DISTRIBUTOR_DEVICES_PATH}`;
export const REPAIR_TIME_ANALYSIS_PATH_FULL = `/${REPORTS_PATH}/${REPAIR_TIME_ANALYSIS_PATH}`;
export const AVERAGE_REPAIR_TIME_PATH_FULL = `/${REPORTS_PATH}/${AVERAGE_REPAIR_TIME_PATH}`;
export const EXCEEDING_REPAIRS_PATH_FULL = `/${REPORTS_PATH}/${EXCEEDING_REPAIRS_PATH}`;
export const REPAIRED_RETURNS_PATH_FULL = `/${REPORTS_PATH}/${REPAIRED_RETURNS_PATH}`;
export const SPARE_PARTS_TOTAL_SPENDING_UPGRADABLE_PATH_FULL = `/${REPORTS_PATH}/${SPARE_PARTS_TOTAL_SPENDING_UPGRADABLE_PATH}`;
