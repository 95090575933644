import classNames from "classnames";
import styles from "./styles.module.scss";
import { FontIcon } from "@fluentui/react";
import { isUndefined } from "lodash-es";

interface IPageWrapperFilterProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  clearFilters?: () => void;
  filterIsOn?: boolean;
}

export const PageWrapperFilter = (props: IPageWrapperFilterProps) => {
  return (
    <div id="filterBlock" className={classNames(styles.pageWrapperSearch, props.className)}>
      {!isUndefined(props.filterIsOn) ? (
        <div className="search-icon">
          <FontIcon onClick={props.clearFilters} iconName={props.filterIsOn ? "FilterSolid" : "Filter"} />
        </div>
      ) : null}
      <div className={styles.pageWrapperFields}>{props.children}</div>
    </div>
  );
};

export const PageWrapperReportFilter = (props: IPageWrapperFilterProps) => {
  return (
    <div className={classNames(styles.pageWrapperReportSearch, props.className)}>
      {props.children}
    </div>
  );
};

export const PageWrapperReportFilters = (props: IPageWrapperFilterProps) => {
  return (
    <div className={classNames(styles.pageWrapperFields, props.className)}>
      {props.children}
    </div>
  );
};

export const PageWrapperReportActions = (props: IPageWrapperFilterProps) => {
  return (
    <div className={classNames(styles.pageWrapperReportActions, props.className)}>
      {props.children}
    </div>
  );
};
