import { CommandbarCommands } from "constants/enums";
import loc from "localization";

interface ICommandBarUploadButtonProps {
  onFileUpload?: (file: File) => void;
  id?: string;
  disabled?: boolean;
}

export const getCommandBarUploadButton = (props: ICommandBarUploadButtonProps) => {
  
  const handleUploadClick = () => {
    const inputElement = document.getElementById(props.id ? props.id : "");
    if (inputElement) {
      (inputElement as HTMLInputElement).click();
    }
  };

  return {
    key: "upload",
    text: loc.buttons.uploadInstruction,
    iconProps: { iconName: "Upload" },
    onClick: handleUploadClick,
    disabled: props.disabled,
    display: true,
    command: CommandbarCommands.None,
    order: 2
  };
};