import { useContext, useEffect, useMemo, useState } from "react";
import { filter, find, map } from "lodash-es";
import { DefaultButton, FontIcon, GroupedList, GroupHeader, IGroup, IGroupHeaderProps, PrimaryButton, SelectionMode } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { deleteSuccessNotification } from "utils";
import { BrowseFaqItemDto } from "generated-sources/openapi";
import { useApi, useHttp } from "hooks";
import { LanguageContext } from "context/languageContext";
import { CommandbarCommands } from "constants/enums";
import { CommandWrapper } from "components/common/CommandWrapper";
import { BasicInput } from "components/common/BasicInput";
import { CommandBar } from "components/common/CommandBar";
import { PageWrapperContent } from "components/common/PageWrapper/Content";
import { PageWrapperDetails } from "components/common/PageWrapper/Details";
import { PageWrapperFilter } from "components/common/PageWrapper/Filter";
import { PageWrapperTitle } from "components/common/PageWrapper/Title";
import { ConfirmModal } from "components/common/ConfirmModal";
import { getActions } from "./config";
import { AddFaqModal } from "./ActionFaqModal";
import styles from "./styles.module.scss";

interface ITheme {
  fieldName?: string,
  id?: number;
  isCollapsed?: boolean;
  level: number;
  count: number;
  startIndex: number;
  key?: string;
  name?: string;
};

interface IAnswer {
  name?: string,
  id?: number;
};

export interface ISelectedRecordInfo extends IAnswer {
  key: string;
};

export const FaqPage = () => {
  const { utilsFaqApi } = useApi();
  const { loc, language } = useContext(LanguageContext);
  const faqHttp = useHttp<BrowseFaqItemDto[]>();
  const faqDeleteHttp = useHttp();
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [themes, setThemes] = useState<ITheme[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedRecordId, setSelectedRecordId] = useState<number>(0);
  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);
  const [selectedRecordInfo, setSelectedRecordInfo] = useState<ISelectedRecordInfo>({
    name: "",
    key: "",
    id: 0
  });

  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);

  const loadData = () => {
    faqHttp
      .request(() => utilsFaqApi.apiUtilsFaqListGet())
      .then((res) => {
        const answers = map(res.data, ({ description, id }) => ({ name: description, id }));
        const themes = map(res.data, ({ theme, id }, i) => ({
          fieldName: theme,
          id: id,
          level: 0,
          isCollapsed: true,
          count: 1,
          startIndex: Number(i),
          key: theme,
          name: theme,
        }));
        setAnswers(answers);
        setThemes(themes);
      });
  };

  const deleteFaq = () => {
    faqDeleteHttp.
      request(() => utilsFaqApi.apiUtilsFaqIdDelete({ id: selectedRecordId }))
      .then((res: AxiosResponse) => {
        deleteSuccessNotification(res);
        handleModalDismiss();
        loadData();
      });
  };


  const getGroupRow = ({ name, id }: ITheme) => {
    const regex = new RegExp(`(${searchValue})`, "gi");
    const partsOfValue = name?.split(regex);
    return (
      <div className={styles.titleWrapper}>
        {searchValue.length ? (
          <div>
            {map(partsOfValue, (part, index) => (
              part.match(regex) ? (
                <span className={styles.selectedPart} key={index}>
                  {part}
                </span>
              ) : (
                <span key={index}>{part}</span>
              )
            ))}
          </div>
        ) : <span>{name}</span>}
        <div className={styles.iconsWrapper}>
          <FontIcon
            iconName="EditSolid12"
            onClick={() => {
              setSelectedRecordInfo({
                id: id,
                key: name || "",
                name: find(answers, { id: id })?.name || ""
              });
              setActiveCommand(CommandbarCommands.Edit);
            }}
          />
          <FontIcon
            iconName="delete"
            onClick={() => {
              setSelectedRecordId(id || 0);
              setActiveCommand(CommandbarCommands.Delete);
            }}
          />
        </div>
      </div>
    );
  };

  const getAnswerWrapper = (answer: string) => ({ __html: answer || "" });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCollapseClick = (group: any) =>
    setThemes(map(
      themes, item => item.id === group.id
        ? ({ ...item, isCollapsed: !item.isCollapsed })
        : item
    ));

  const onRenderCell = (
    nestingDepth?: number,
    item?: IAnswer,
  ) => (
    <div className="group-row">
      <div dangerouslySetInnerHTML={getAnswerWrapper(String(item?.name))}></div>
    </div>
  );

  useEffect(() => loadData(), []);

  const actions = useMemo(() => getActions(), [language]);

  const filterIsOn = useMemo(() => Boolean(searchValue), [searchValue]);

  const filteredGroups = useMemo(() => {
    return filter(themes, (item: ITheme) => item.name?.toLowerCase().includes(searchValue.toLowerCase()));
  }, [searchValue, themes]) as ITheme[];

  return (
    <>
      <CommandBar actions={actions} setActiveCommand={setActiveCommand} />
      <PageWrapperContent className={styles.faqPage}>
        <PageWrapperTitle title={loc.menu.faqPage} />
        <PageWrapperFilter
          filterIsOn={filterIsOn}
          clearFilters={() => setSearchValue("")}
          className={styles.filterWrapper}
        >
          <BasicInput
            className="name-field"
            value={searchValue}
            onChange={setSearchValue}
            placeholder={loc.main.searchPlaceholder}
            reset
          />
        </PageWrapperFilter>
        <PageWrapperDetails isLoading={faqHttp.isLoading} noData={!themes?.length}>
          <GroupedList
            groupProps={{
              onRenderHeader: (props?: IGroupHeaderProps) => (
                <GroupHeader
                  {...props}
                  styles={{
                    headerCount: {
                      display: "none"
                    },
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onRenderName={(props: any) => getGroupRow(props.group)}
                  onGroupHeaderClick={onCollapseClick}
                />
              )
            }}
            compact
            items={answers}
            onRenderCell={onRenderCell}
            groups={filteredGroups as IGroup[]}
            selectionMode={SelectionMode.none}
          />
        </PageWrapperDetails>

        <CommandWrapper isActive={activeCommand === CommandbarCommands.Add}>
          <AddFaqModal
            updateState={loadData}
            onDismiss={handleModalDismiss}
          />
        </CommandWrapper>
        <CommandWrapper isActive={activeCommand === CommandbarCommands.Edit}>
          <AddFaqModal
            selectedRecordInfo={selectedRecordInfo}
            onDismiss={handleModalDismiss}
            updateState={loadData}
            editAction
          />
        </CommandWrapper>
        <CommandWrapper isActive={activeCommand === CommandbarCommands.Delete}>
          <ConfirmModal
            isLoading={faqDeleteHttp.isLoading}
            text={loc.faqPage.deleteRecord}
            leftButton={<PrimaryButton text={loc.buttons.yes} onClick={deleteFaq} />}
            rightButton={<DefaultButton text={loc.buttons.no} onClick={handleModalDismiss} />}
            errors={faqDeleteHttp.errorMessages}
            onDismiss={handleModalDismiss}
          />
        </CommandWrapper>
      </PageWrapperContent>
    </>
  );
};