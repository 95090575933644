
import { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import { AxiosResponse } from "axios";
import "react-quill/dist/quill.snow.css";
import { useApi, useHttp } from "hooks";
import { createSuccessNotification, editSuccessNotification } from "utils";
import loc from "localization";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicInput } from "components/common/BasicInput";
import { ISelectedRecordInfo } from "..";
import styles from "./styles.module.scss";

export interface IAddFaqModalProps {
  editAction?: boolean;
  updateState: () => void;
  selectedRecordInfo?: ISelectedRecordInfo;
  onDismiss: () => void;
}

export const AddFaqModal = (props: IAddFaqModalProps) => {
  const [theme, setTheme] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");
  const isEditAction = props.editAction;
  const quillRef = useRef(null);
  const { utilsFaqApi } = useApi();
  const { isLoading, request, errorMessages } = useHttp();

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image"],
      ],
    },
  };

  const createFaq = () => {
    request(() => utilsFaqApi.apiUtilsFaqPost({
      createFaqPayload: {
        theme: theme,
        description: answer
      }
    }))
      .then((res: AxiosResponse) => {
        createSuccessNotification(res);
        props.updateState();
        props.onDismiss();
      });
  };

  const editFaq = () => {
    request(() => utilsFaqApi.apiUtilsFaqIdPut({
      id: Number(props.selectedRecordInfo?.id),
      updateFaqPayload: {
        theme: theme,
        description: answer,
      }
    }))
      .then((res: AxiosResponse) => {
        editSuccessNotification(res);
        props.updateState();
        props.onDismiss();
      });
  };

  const submitHandler = isEditAction && props.selectedRecordInfo ? editFaq : createFaq;

  useEffect(() => {
    if (props.selectedRecordInfo && isEditAction) {
      setTheme(props.selectedRecordInfo.key);
      setAnswer(String(props.selectedRecordInfo.name));
    };
  }, [props.selectedRecordInfo]);

  return (
    <BasicModal
      additionalClassName={styles.addFaq}
      isLoading={isLoading}
      errors={errorMessages}
      title={isEditAction ? loc.faqPage.editNote : loc.faqPage.addingNote}
      submitButtonText={loc.buttons.add}
      dismissButtonText={loc.buttons.close}
      onSubmit={submitHandler}
      onDismiss={props.onDismiss}
      disabledSubmitButton={!theme.length || !answer.length}
    >
      <BasicModalContent>
        <>
          <InfoContainer
            infoContainerClassName={styles.flexColumn}
            infoContainerValueClassName={styles.editor}
            label={loc.faqPage.question}
          >
            <div className="flex-row">
              <BasicInput
                className={styles.themeInput}
                value={theme}
                onChange={setTheme}
                reset
              />
            </div>

          </InfoContainer>
          <InfoContainer
            infoContainerClassName={styles.flexColumn}
            label={loc.faqPage.answer}
            infoContainerValueClassName={styles.editor}
          >
            <ReactQuill
              id="editor"
              ref={quillRef}
              value={answer}
              onChange={setAnswer}
              modules={modules}
            />
          </InfoContainer>
        </>
      </BasicModalContent>
    </BasicModal>
  );
};