import { useState } from "react";
import * as api from "generated-sources/openapi/api";

export interface IApiFacade {
  defectsApi: api.DictDefectsApi;
  dictSerialsApi: api.DictSerialsApi;
  blamesApi: api.DictBlamesApi;
  deviceGroupsApi: api.DictDeviceGroupsApi;
  repairGroupsApi: api.DictRepairGroupsApi;
  counterpartiesApi: api.DictCounterpartiesApi;
  maintainabilityApi: api.DictMaintainabilityApi;
  requestsRMAApi: api.OpRmaRequestsApi;
  permissionsRMAApi: api.OpRmasApi;
  repairDevicesApi: api.DictRepairDevicesApi;
  currencyApi: api.DictCurrenciesApi;
  invoicesApi: api.OpInvoicesApi;
  maintenanceApi: api.OpMaintenanceApi;
  stockAreasApi: api.DictStockAreasApi;
  utilsEmailsLogApi: api.UtilsEmailsLogApi;
  devicesApi: api.OpDevicesApi;
  employeesApi: api.DictEmployeesApi;
  repairPlansApi: api.OpRepairPlansApi;
  customersInfoApi: api.OpDistributorsApi;
  todayEventsApi: api.UtilsTodayEventsApi;
  repairAreasApi: api.OpRepairAreasApi;
  summaryOfReplacementsApi: api.OpReplacementFundApi;
  reportsReplacementPartsApi: api.ReportsReplacementPartsApi;
  reportsDefectsComplianceApi: api.ReportsDefectsComplianceApi;
  reportsLicensesUsedApi: api.ReportsLicensesUsedApi;
  reportsNotShippedDevicesApi: api.ReportsNotShippedDevicesApi;
  reportListOfComplaintsApi: api.ReportsListOfComplaintsApi;
  reportsWarehouseMovementApi: api.ReportsWarehouseMovementApi;
  reportsRepairTimeApi: api.ReportsRepairTimeApi;
  reportsRepairTimeOtkApi: api.ReportsRepairTimeOtkApi;
  reportsRepairPlansFulfillmentApi: api.ReportsRepairPlansFulfillmentApi;
  reportsUnplannedDevicesApi: api.ReportsUnplannedDevicesApi;
  reportsDefectsMovementApi: api.ReportsDefectsMovementApi;
  reportsDistributorsRatingApi: api.ReportsDistributorsRatingApi;
  reportsTransportCostsApi: api.ReportsTransportCostsApi;
  reportsIncomeForecastsApi: api.ReportsIncomeForecastsApi;
  reportsRepairedDevicesApi: api.ReportsRepairedDevicesApi;
  searchApi: api.OpSearchApi;
  reportsReturnsReasonsApi: api.ReportsReturnsReasonsApi;
  reportsRepairPriceProstafApi: api.ReportsRepairPriceProstafApi;
  reportsGeneralAverageRepairTimeApi: api.ReportsGeneralAverageRepairTimeApi;
  reportsCategoryAApi: api.ReportsCategoryAApi;
  paidReportApi: api.ReportsPaidRepairApi;
  reportsReturnLevelApi: api.ReportsReturnLevelApi;
  settingsUsersApi: api.SettingsUsersApi;
  userDirectoryApi: api.UserDirectoryApi;
  settingsNotificationsApi: api.SettingsNotificationsApi;
  dashboardApi: api.DashboardApi;
  reportsDeviceArrivalTimeApi: api.ReportsDeviceArrivalTimeApi;
  reportsListOfComplaintsExternalApi: api.ReportsListOfComplaintsExternalApi;
  reportsRepeatedReturnsApi: api.ReportsRepeatedReturnsApi;
  dictDocNotesApi: api.DictDocNotesApi;
  opWarantyApi: api.OpWarrantyApi;
  opExternalServiceCenters: api.OpExternalServiceCentersApi;
  reportsServiceCostApi: api.ReportsServiceCostsApi;
  addedPartsApi: api.ReportsAddedPartsApi;
  sparePartsApi: api.OpSparePartsApi;
  dictProdDevices: api.DictProductionDevicesApi;
  reportsRepairApi: api.ReportsRepairApi;
  reportsSparePartsApi: api.ReportsSparePartsApi;
  upgradeDevicesReportApi: api.ReportsUpgradeDevicesApi;
  distributorDevicesApi: api.ReportsDistributorsDevicesApi;
  reportsRepairTimeAnalisys: api.ReportsRepairTimeAnalisysApi;
  utilsFaqApi: api.UtilsFaqApi;
  dailyReportsRepeatedReturnsApi: api.DailyReportsRepeatedReturnsApi;
  reportsRepairDeadlinesExceededApi: api.ReportsRepairDeadlinesExceededApi;
  recurringReportsApi: api.RecurringReportsApi;
  dictWorksApi: api.DictWorksApi;
}

export const useApi = (): IApiFacade => {
  const [apiFacade] = useState<IApiFacade>({
    defectsApi: new api.DictDefectsApi(),
    dictSerialsApi: new api.DictSerialsApi(),
    blamesApi: new api.DictBlamesApi(),
    deviceGroupsApi: new api.DictDeviceGroupsApi(),
    repairGroupsApi: new api.DictRepairGroupsApi(),
    counterpartiesApi: new api.DictCounterpartiesApi(),
    maintainabilityApi: new api.DictMaintainabilityApi(),
    requestsRMAApi: new api.OpRmaRequestsApi(),
    permissionsRMAApi: new api.OpRmasApi(),
    repairDevicesApi: new api.DictRepairDevicesApi(),
    currencyApi: new api.DictCurrenciesApi(),
    invoicesApi: new api.OpInvoicesApi(),
    maintenanceApi: new api.OpMaintenanceApi(),
    stockAreasApi: new api.DictStockAreasApi(),
    utilsEmailsLogApi: new api.UtilsEmailsLogApi(),
    devicesApi: new api.OpDevicesApi(),
    employeesApi: new api.DictEmployeesApi(),
    repairPlansApi: new api.OpRepairPlansApi(),
    customersInfoApi: new api.OpDistributorsApi(),
    todayEventsApi: new api.UtilsTodayEventsApi(),
    repairAreasApi: new api.OpRepairAreasApi(),
    summaryOfReplacementsApi: new api.OpReplacementFundApi(),
    reportsReplacementPartsApi: new api.ReportsReplacementPartsApi(),
    reportsDefectsComplianceApi: new api.ReportsDefectsComplianceApi(),
    reportsLicensesUsedApi: new api.ReportsLicensesUsedApi(),
    reportsNotShippedDevicesApi: new api.ReportsNotShippedDevicesApi(),
    reportListOfComplaintsApi: new api.ReportsListOfComplaintsApi(),
    reportsWarehouseMovementApi: new api.ReportsWarehouseMovementApi(),
    reportsRepairTimeApi: new api.ReportsRepairTimeApi(),
    reportsRepairTimeOtkApi: new api.ReportsRepairTimeOtkApi(),
    reportsRepairPlansFulfillmentApi: new api.ReportsRepairPlansFulfillmentApi(),
    reportsUnplannedDevicesApi: new api.ReportsUnplannedDevicesApi(),
    reportsDefectsMovementApi: new api.ReportsDefectsMovementApi(),
    reportsDistributorsRatingApi: new api.ReportsDistributorsRatingApi(),
    reportsTransportCostsApi: new api.ReportsTransportCostsApi(),
    reportsIncomeForecastsApi: new api.ReportsIncomeForecastsApi(),
    reportsRepairedDevicesApi: new api.ReportsRepairedDevicesApi(),
    searchApi: new api.OpSearchApi(),
    reportsReturnsReasonsApi: new api.ReportsReturnsReasonsApi(),
    reportsRepairPriceProstafApi: new api.ReportsRepairPriceProstafApi(),
    reportsGeneralAverageRepairTimeApi: new api.ReportsGeneralAverageRepairTimeApi(),
    reportsCategoryAApi: new api.ReportsCategoryAApi(),
    paidReportApi: new api.ReportsPaidRepairApi(),
    reportsReturnLevelApi: new api.ReportsReturnLevelApi(),
    settingsUsersApi: new api.SettingsUsersApi(),
    userDirectoryApi: new api.UserDirectoryApi(),
    settingsNotificationsApi: new api.SettingsNotificationsApi(),
    dashboardApi: new api.DashboardApi(),
    reportsDeviceArrivalTimeApi: new api.ReportsDeviceArrivalTimeApi(),
    reportsListOfComplaintsExternalApi: new api.ReportsListOfComplaintsExternalApi(),
    reportsRepeatedReturnsApi: new api.ReportsRepeatedReturnsApi(),
    dictDocNotesApi: new api.DictDocNotesApi(),
    opWarantyApi: new api.OpWarrantyApi(),
    opExternalServiceCenters: new api.OpExternalServiceCentersApi(),
    reportsServiceCostApi: new api.ReportsServiceCostsApi(),
    addedPartsApi: new api.ReportsAddedPartsApi(),
    sparePartsApi: new api.OpSparePartsApi(),
    dictProdDevices: new api.DictProductionDevicesApi(),
    reportsRepairApi: new api.ReportsRepairApi(),
    reportsSparePartsApi: new api.ReportsSparePartsApi(),
    upgradeDevicesReportApi: new api.ReportsUpgradeDevicesApi(),
    distributorDevicesApi: new api.ReportsDistributorsDevicesApi(),
    reportsRepairTimeAnalisys: new api.ReportsRepairTimeAnalisysApi(),
    utilsFaqApi: new api.UtilsFaqApi(),
    dailyReportsRepeatedReturnsApi: new api.DailyReportsRepeatedReturnsApi(),
    reportsRepairDeadlinesExceededApi: new api.ReportsRepairDeadlinesExceededApi(),
    recurringReportsApi: new api.RecurringReportsApi(),
    dictWorksApi: new api.DictWorksApi(),
  });
  return apiFacade;
};
