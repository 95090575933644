import { CommandbarCommands } from "constants/enums";
import loc from "localization";

export const getActions = () => [
  {
    key: "add",
    text: loc.buttons.add,
    iconName: "add",
    command: CommandbarCommands.Add,
    disabled: false,
    display: true,
  },
];