import loc from "localization";

export const initPermissions = {
  dashboardPage: false,
  defectsPage: false,
  defectsPageAction: false,
  serialNumsTrackingPage: false,
  serialNumsTrackingAction: false,
  normHoursPage: false,
  normHoursAction: false,
  maintainabilityPage: false,
  maintainabilityAction: false,
  requestsRMAPage: false,
  permissionsRMAPage: false,
  devicesPage: false,
  invoicesPage: false,
  devicesAction: false,
  servicePage: false,
  repairPlanPage: false,
  serviceCentersDatauploadPage: false,
  manageWarehousePermission: false,
  workExecutedPermission: false,
};

export const requestStatuses = () => ({
  InProcess: loc.statuses.inProcess,
  Submitted: loc.statuses.submitted,
  Received: loc.statuses.received,
  Approved: loc.statuses.approved,
  Closed: loc.statuses.closed,
  Rejected: loc.statuses.rejected,
});
