import { useId } from "react";
import { map, orderBy } from "lodash-es";
import { CommandBar as CommandBarFabric } from "@fluentui/react";
import { CommandbarCommands } from "constants/enums";
import styles from "./styles.module.scss";
import { getCommandBarUploadButton } from "./helpers";

export interface ICommandBarAction {
  key: string;
  text: string;
  iconName?: string;
  command: CommandbarCommands;
  disabled: boolean;
  display: boolean;
  items?: ICommandBarAction[];
  threeDots?: boolean;
  render?: (props: ICommandBarAction) => JSX.Element;
  order?: number;
}

interface ICommandBarProps {
  onFileUpload?: (file: File) => void;
  additionalButtonDisabled?: boolean;
  additionalButton?: boolean;
  actions: ICommandBarAction[];
  setActiveCommand: (value: CommandbarCommands) => void;
}

export const CommandBar = (props: ICommandBarProps) => {
  const { onFileUpload } = props;
  const inputElementId = useId();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      props.onFileUpload && props.onFileUpload(selectedFile);
    }
  };

  const getMenuPropsItems = (actions: ICommandBarAction[]) =>
    map(actions, (action: ICommandBarAction) => ({
      ...action,
      iconProps: { iconName: action.iconName },
      onClick: () => props.setActiveCommand(action.command),
      subMenuProps: action?.items && {
        items: map(action.items, (item: ICommandBarAction) => ({
          ...item,
          onClick: () => props.setActiveCommand(item.command),
        })),
      },
    })).concat(props.additionalButton ? [getCommandBarUploadButton(
      { onFileUpload, id: inputElementId, disabled: props.additionalButtonDisabled }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )] as any : []);

  return (
    <div className={styles.commandBar}>
      <>
        {props.additionalButton ? (
          <input
            id={inputElementId}
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        ) : null}
        <CommandBarFabric items={orderBy(getMenuPropsItems(props.actions), "order") } />
      </>
    </div>
  );
};
